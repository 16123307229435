// Libraries
import React from 'react';

// Supermove
import {RichTextInput, Space, Styled} from '@supermove/components';
import {Form, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App

import FieldInput from '@shared/design/components/Field/FieldInput';
import {WrappedEmailFormType} from '@shared/modules/Email/hooks/useSendEmailForProjectMutation';
import Field from 'modules/App/components/Field';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Subject = ({field, form}: {field: string; form: Form<WrappedEmailFormType>}) => {
  return (
    <React.Fragment>
      <Label>Subject</Label>
      <Space height={2} />
      <FieldInput
        {...form}
        name={`${field}.subject`}
        isResponsive
        input={{
          placeholder: 'Enter subject',
          style: {
            width: '100%',
          },
        }}
      />
    </React.Fragment>
  );
};

const EmailContentSection = ({
  field,
  form,
  hideSubject,
  autoFocus,
  textEditorHeight,
}: {
  field: string;
  form: Form<WrappedEmailFormType>;
  hideSubject?: boolean;
  autoFocus?: boolean;
  textEditorHeight?: number;
}) => {
  const responsive = useResponsive();

  return (
    <Container>
      {hideSubject ? null : <Subject field={field} form={form} />}
      <Space height={12} />
      <Field
        {...form}
        name={`${field}.body`}
        component={RichTextInput}
        input={{
          placeholder: 'Enter body',
          setFieldValue: form.setFieldValue,
          style: {
            paddingVertical: 9,
          },
          editorStyle: {
            height: textEditorHeight || (responsive.mobile ? 390 : 366),
          },
          autoFocus,
        }}
      />
    </Container>
  );
};

EmailContentSection.Subject = Subject;

export default EmailContentSection;
