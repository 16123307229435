// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useState} from '@supermove/hooks';
import {ProjectModel, UserModel} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import EmailForm, {EmailFormType} from '@shared/modules/Email/forms/EmailForm';
import useSendEmailForProjectMutation from '@shared/modules/Email/hooks/useSendEmailForProjectMutation';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelectorWithDrawer from 'modules/File/Attachment/components/EmailAttachmentsSelectorWithDrawer';

const Container = Styled.View`
  max-width: 600px;
`;

const Title = Styled.Text`
    ${Typography.Responsive.Heading1}
`;

const FromLabel = Styled.Text`
    ${Typography.Responsive.Body}
`;

interface EmailReplyProps {
  handleCancel: () => void;
  responsive: ResponsiveType;
  handleEmailSent: () => void;
  project: ProjectModel;
  viewer: UserModel;
  fromEmail: string;
  form: EmailFormType;
}

const EmailReply = ({
  handleCancel,
  responsive,
  handleEmailSent,
  project,
  viewer,
  fromEmail,
  form: parentForm,
}: EmailReplyProps) => {
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const {form, submitting, handleSubmit} = useSendEmailForProjectMutation({
    project,
    emailForm: EmailForm.toForm(parentForm),
    onSuccess: handleEmailSent,
    onError: (errors) => console.log({errors}),
  });

  return (
    <Container>
      <Title responsive={responsive}>Reply</Title>
      {/* When this flag is on, `from` is in the fields component */}
      {project.organization.features.isEnabledSelectFromAccount ? (
        <Space height={16} />
      ) : (
        <React.Fragment>
          <Space height={16} />
          <FromLabel responsive={responsive}>From</FromLabel>
          <FieldInput name='from' input={{isDisabled: true}} value={fromEmail} />
          <Space height={16} />
        </React.Fragment>
      )}
      <ProjectEmailFields
        field={'emailForm'}
        form={form}
        viewer={viewer}
        project={project}
        setIsGeneratingPreview={setIsGeneratingPreview}
        isReply
        autoFocus
        textEditorHeight={284}
      />
      <Space height={40} />
      <EmailAttachmentsSelectorWithDrawer form={form} project={project} field={'emailForm'} />
      <Space height={responsive.mobile ? 16 : 40} />
      <Button
        text={'Send'}
        isSubmitting={submitting}
        isDisabled={isGeneratingPreview}
        onPress={handleSubmit}
        isResponsive
        isWidthOfContainer
      />
      <Space height={16} />
      <SecondaryButton isWidthOfContainer isResponsive text='Cancel' onPress={handleCancel} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailReply.fragment = gql`
  ${ProjectEmailFields.fragment}
  ${EmailAttachmentsSelectorWithDrawer.fragment}

  fragment EmailReply_Project on Project {
    id
    uuid
    ...EmailAttachmentsSelectorWithDrawer
    ...ProjectEmailFields_Project
    organization {
      id
      features {
        isEnabledSelectFromAccount: isEnabled(feature: "SELECT_FROM_ACCOUNT")
      }
    }
  }

  fragment EmailReply_Thread on Thread {
    id
    name
  }

  fragment EmailReply_User on User {
    id
    ...ProjectEmailFields_User
  }
`;

export default EmailReply;
